<template>
  <v-card id="card-modal-assign-driver" class="elevation-4">
    <v-card-title class="justify-start py-2 card-modaltitle">
      <div class="pl-4">
        <h3 class="primary--text pl-4">
          {{ $t("components.views.trip.offers.transport_company.modal_form_counter_offer.title") }}
        </h3>
      </div>
      <v-btn depressed text color="primary" class="cont" @click.stop="$emit('closeForm')">
        <v-icon class="mr-1">fa-solid fa-xmark</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text class="pa-1">
      <v-container fluid class="pt-0 mt-0">
        <v-stepper v-model="e1" class="elevation-0">
          <v-row>
            <v-col cols="12" class="pt-0 mt-0">
              <v-stepper-header class="elevation-0">
                <v-stepper-step :complete="e1 > 1" step="1">
                  {{
                    $t(
                      "components.views.trip.offers.transport_company.modal_form_counter_offer.value"
                    )
                  }}
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step :complete="e1 > 2" step="2">
                  {{
                    $t(
                      "components.views.trip.offers.transport_company.modal_form_counter_offer.driver"
                    )
                  }}
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step :complete="e1 > 3" step="3">
                  {{
                    $t(
                      "components.views.trip.offers.transport_company.modal_form_counter_offer.vehicle"
                    )
                  }}
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step :complete="e1 > 4" step="4">
                  {{
                    $t(
                      "components.views.trip.offers.transport_company.modal_form_counter_offer.finish"
                    )
                  }}
                </v-stepper-step>
              </v-stepper-header>
            </v-col>
          </v-row>
          <v-stepper-items>
            <v-row>
              <v-col cols="12">
                <v-stepper-content step="1" class="pa-0">
                  <v-container class="py-20">
                    <v-form v-model="validTab1" @submit.prevent>
                      <v-row class="justify-center">
                        {{
                          $t(
                            "components.views.trip.offers.transport_company.modal_form_counter_offer.value_for_service"
                          )
                        }}
                      </v-row>
                      <v-row class="justify-center mt-4">
                        {{
                          $t(
                            "components.views.trip.offers.transport_company.modal_form_counter_offer.value_init"
                          )
                        }}: {{ formatMoney(value) }}
                      </v-row>
                      <v-row class="justify-center">
                        <v-col cols="10">
                          <v-text-field
                            v-model="amount"
                            required
                            outlined
                            dense
                            :rules="[
                              required(
                                $t(
                                  'components.views.trip.offers.transport_company.modal_form_counter_offer.value_for_service'
                                )
                              ),
                            ]"
                            :label="
                              $t(
                                'components.views.trip.offers.transport_company.modal_form_counter_offer.value_for_service'
                              )
                            "
                            prepend-icon="fa-solid fa-dollar-sign"
                          />
                        </v-col>
                      </v-row>
                      <v-row class="justify-center mt-10 mb-2">
                        <v-btn color="primary" @click="e1 = 2" depressed :disabled="!validTab1">
                          <v-icon small class="mr-1">fa-solid fa-arrow-right</v-icon>
                          {{
                            $t(
                              "components.views.trip.offers.transport_company.modal_form_counter_offer.next"
                            )
                          }}
                        </v-btn>
                      </v-row>
                    </v-form>
                  </v-container>
                </v-stepper-content>
                <v-stepper-content step="2" class="py-0">
                  <v-container class="py-20">
                    <v-row class="justify-center">
                      {{
                        $t(
                          "components.views.trip.offers.transport_company.modal_form_counter_offer.assign_driver"
                        )
                      }}
                    </v-row>
                    <v-form v-model="validTab2" @submit.prevent>
                      <v-row class="justify-center">
                        <v-col cols="10">
                          <SelectAutocomplete
                            ref="driver"
                            v-model="driver"
                            url="/Drivers"
                            :search-input.sync="driverCreate"
                            return-object
                            item-text="identification"
                            item-value="identification"
                            required
                            dense
                            :rules="[
                              required(
                                $t(
                                  'components.views.trip.offers.transport_company.modal_form_counter_offer.number_id'
                                ),
                                1
                              ),
                            ]"
                            prepend-icon="fa-solid fa-id-card"
                            :label="
                              $t(
                                'components.views.trip.offers.transport_company.modal_form_counter_offer.number_id'
                              )
                            "
                            :placeholder="
                              $t(
                                'components.views.trip.offers.transport_company.modal_form_counter_offer.number_id'
                              )
                            "
                            hide-details
                            maxlength="15"
                          >
                            <template slot="no-data">
                              <v-list-item @click="loadModalNewDriver()">
                                <v-list-item-title>{{
                                  $t(
                                    "components.views.trip.no_offer_assign.modal_assign.create_new_driver"
                                  )
                                }}</v-list-item-title>
                              </v-list-item>
                            </template>
                          </SelectAutocomplete>
                        </v-col>
                      </v-row>
                      <v-row class="justify-center">
                        <v-col cols="10" class="pt-2 pb-0">
                          <v-text-field
                            v-model="driverNameComputed"
                            required
                            outlined
                            readonly
                            dense
                            hide-details
                            :label="
                              $t(
                                'components.views.trip.offers.transport_company.modal_form_counter_offer.names_last_names'
                              )
                            "
                            :placeholder="
                              $t(
                                'components.views.trip.offers.transport_company.modal_form_counter_offer.names_last_names'
                              )
                            "
                            prepend-icon="fa-solid fa-user"
                          />
                        </v-col>
                      </v-row>
                      <v-row class="justify-center mt-10 mb-2" >
                        <v-btn color="secondary" @click="e1 = 1" depressed>
                          <v-icon small class="mr-1">fa-solid fa-arrow-left</v-icon>
                          {{
                            $t(
                              "components.views.trip.offers.transport_company.modal_form_counter_offer.previous"
                            )
                          }}
                        </v-btn>
                        <v-btn
                          color="primary"
                          @click="e1 = 3"
                          class="ml-4 "
                          depressed
                          :disabled="!validTab2"
                        >
                          <v-icon small class="mr-1">fa-solid fa-arrow-right</v-icon>
                          {{
                            $t(
                              "components.views.trip.offers.transport_company.modal_form_counter_offer.next"
                            )
                          }}
                        </v-btn>
                        <v-btn
                          color="grey lighten-1 white--text"
                          class="ml-4"
                          depressed
                          @click.stop="$emit('closeForm')"
                        >
                          <v-icon small size="15" class="mr-1"> fa-solid fa-xmark </v-icon>
                          {{
                            $t(
                              "components.views.trip.offers.transport_company.modal_form_counter_offer.cancel"
                            )
                          }}
                        </v-btn>
                      </v-row>
                    </v-form>
                  </v-container>
                </v-stepper-content>
                <v-stepper-content step="3" class="pa-0">
                  <v-container class="py-8">
                    <v-row class="justify-center">
                      {{
                        $t(
                          "components.views.trip.offers.transport_company.modal_form_counter_offer.assign_vehicle"
                        )
                      }}
                    </v-row>
                    <v-form v-model="validTab3" @submit.prevent>
                      <v-row class="justify-center">
                        <v-col cols="10">
                          <SelectAutocomplete
                            ref="vehicle"
                            v-model="vehicle"
                            url="/Vehicles"
                            return-object
                            item-text="licensePlate"
                            :search-input.sync="vehicleCreate"
                            maxlength="10"
                            required
                            dense
                            :rules="[
                              required(
                                $t(
                                  'components.views.trip.offers.transport_company.modal_form_counter_offer.license_plate'
                                ),
                                1
                              ),
                            ]"
                            item-value="licensePlate"
                            prepend-icon="fa-solid fa-truck-moving"
                            :label="
                              $t(
                                'components.views.trip.offers.transport_company.modal_form_counter_offer.license_plate'
                              )
                            "
                            :placeholder="
                              $t(
                                'components.views.trip.offers.transport_company.modal_form_counter_offer.license_plate'
                              )
                            "
                            hide-details
                            >
                            <template slot="no-data">
                              <v-list-item @click="loadModalNewVehicle()">
                                <v-list-item-title>{{
                                  $t(
                                    "components.views.trip.no_offer_assign.modal_assign.create_new_vehicle"
                                  )
                                }}</v-list-item-title>
                              </v-list-item>
                            </template>
                          </SelectAutocomplete>
                        </v-col>
                      </v-row>
                      <v-row class="justify-center mt-10 mb-2">
                        <v-btn color="secondary" @click="e1 = 2" depressed>
                          <v-icon small class="mr-1">fa-solid fa-arrow-left</v-icon>
                          {{
                            $t(
                              "components.views.trip.offers.transport_company.modal_form_counter_offer.previous"
                            )
                          }}
                        </v-btn>
                        <v-btn
                          color="primary"
                          @click="e1 = 4"
                          class="ml-4"
                          depressed
                          :disabled="!validTab3"
                        >
                          <v-icon small class="mr-1">fa-solid fa-arrow-right</v-icon>
                          {{
                            $t(
                              "components.views.trip.offers.transport_company.modal_form_counter_offer.next"
                            )
                          }}
                        </v-btn>
                        <v-btn
                          color="grey lighten-1 white--text"
                          class="ml-4"
                          depressed
                          @click.stop="$emit('closeForm')"
                        >
                          <v-icon small size="15" class="mr-1"> fa-solid fa-xmark </v-icon>
                          {{
                            $t(
                              "components.views.trip.offers.transport_company.modal_form_counter_offer.cancel"
                            )
                          }}
                        </v-btn>
                      </v-row>
                    </v-form>
                  </v-container>
                </v-stepper-content>
                <v-stepper-content step="4" class="pa-0">
                  <v-container class="py-8">
                    <v-row class="justify-center">
                      <v-col cols="3">
                        {{
                          $t(
                            "components.views.trip.offers.transport_company.modal_form_counter_offer.rate"
                          )
                        }}:
                      </v-col>
                      <v-col cols="7"> ${{ amount }}</v-col>
                    </v-row>
                    <v-row class="justify-center">
                      <v-col cols="3">
                        {{
                          $t(
                            "components.views.trip.offers.transport_company.modal_form_counter_offer.driver"
                          )
                        }}:
                      </v-col>
                      <v-col cols="7"> {{ driverNameComputed }} </v-col>
                    </v-row>
                    <v-row class="justify-center">
                      <v-col cols="3">
                        {{
                          $t(
                            "components.views.trip.offers.transport_company.modal_form_counter_offer.vehicle"
                          )
                        }}:
                      </v-col>
                      <v-col cols="7"> {{ vehicle.licensePlate }}</v-col>
                    </v-row>
                    <v-row class="justify-center mt-10 mb-2">
                      <v-btn color="secondary" @click="e1 = 3" depressed>
                        <v-icon small class="mr-1">fa-solid fa-arrow-left</v-icon>
                        {{
                          $t(
                            "components.views.trip.offers.transport_company.modal_form_counter_offer.previous"
                          )
                        }}
                      </v-btn>
                      <v-btn color="primary" class="ml-4" depressed @click="confirm()">
                        <v-icon small class="mr-1">fa-solid fa-solid fa-check</v-icon>
                        {{
                          $t(
                            "components.views.trip.offers.transport_company.modal_form_counter_offer.confirm"
                          )
                        }}
                      </v-btn>
                      <v-btn
                        color="grey lighten-1 white--text"
                        class="ml-4"
                        depressed
                        @click.stop="$emit('closeForm')"
                      >
                        <v-icon small size="15" class="mr-1"> fa-solid fa-xmark </v-icon>
                        {{
                          $t(
                            "components.views.trip.offers.transport_company.modal_form_counter_offer.cancel"
                          )
                        }}
                      </v-btn>
                    </v-row>
                  </v-container>
                </v-stepper-content>
              </v-col>
            </v-row>
          </v-stepper-items>
        </v-stepper>
      </v-container>
    </v-card-text>
    <v-dialog v-model="dialogDriver" scrollable width="35%" persistent>
      <ModalCreateDriver
        :idDriver="driverCapture"
        :idCompany="idCompanyTransporter"
        @closeForm="onCloseFormDriver()"
        @driverCreated="onDriverCreated"
      />
    </v-dialog>
    <v-dialog v-model="dialogVehicle" scrollable width="20%" persistent>
      <ModalCreateVehicle
        :key="index"
        :idLicensePlate="vehicleCapture"
        :idCompany="idCompanyTransporter"
        @closeForm="onCloseFormVehicle()"
        @vehicleCreated="onVehicleCreated"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import validate from "@/plugins/validate";
export default {
  components: {
    ModalCreateDriver: () =>
      import(
        /* webpackChunkName: "ModalCreateDriver" */ "@/components/views/Trip/NoOfferAssign/ModalCreateDriver.vue"
      ),
    ModalCreateVehicle: () =>
      import(
        /* webpackChunkName: "ModalCreateVehicle" */ "@/components/views/Trip/NoOfferAssign/ModalCreateVehicle.vue"
      ),
  },
  props: {
    idMonitoringOrder: {
      type: Number,
      default: 0,
    },
    value: {
      type: Number,
      default: 0,
    },
    idCompanyTransporter: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      ...validate,
      validTab1: false,
      validTab2: false,
      validTab3: false,
      dialogDriver: false,
      driverCapture: "",
      driverCreate: "",
      amount: null,
      driverName: "",
      vehicle: {},
      e1: 1,
      driver: {},
      dialogVehicle: false,
      vehicleCreate: null,
      vehicleCapture: "",
      index: 1
    };
  },
  computed: {
    driverNameComputed() {
      return this.driverName !== "" ? this.driverName : "";
    },
    ...mapGetters({
      user: "auth/user",
    }),
    unlockUser() {
      return JSON.parse(this.user);
    },
    idCompany() {
      return this.unlockUser.user.idCompany;
    },
  },
  watch: {
    driver(newVal) {
      if (newVal) {
        this.driverName = this.driver.name + " " + this.driver.lastName;
      }
    },
  },
  methods: {
    ...mapActions("snackbar", ["showSnack"]),
    ...mapActions("loader", ["showLoader"]),

    async confirm() {
      this.showLoader({
        show: false,
      });

      let params = {
        idMonitoringOrder: this.idMonitoringOrder,
        idTransporter: this.idCompany,
        amount: parseInt(this.amount),
        licensePlate: this.vehicle.licensePlate,
        idDriver: this.driver.idDriver,
      };

      await this.axios
        .post("Offers/RequestTransportCompany", params)
        .then(() => {
          this.showSnack({
            text: this.$t(
              "components.views.trip.offers.transport_company.modal_form_counter_offer.description_confirm_success"
            ),
            title: this.$t(
              "components.views.trip.offers.transport_company.modal_form_counter_offer.success"
            ),
            name: "success",
          });
        })
        .catch((error) => {
          this.showSnack({
            text: error.response.data,
            title: "Error",
            name: "error",
          });
        })
        .finally(() => {
          this.showLoader({
            show: false,
          });
          this.$emit("reloadPage");
        });
    },

    onCloseFormDriver() {
      this.dialogDriver = false;
    },

    onOpenFormDriver() {
      this.dialogDriver = true;
    },

    loadModalNewDriver() {
      let driver = this.driverCreate;
      this.driverCapture = driver;
      this.onOpenFormDriver();
    },

    async onDriverCreated(data) {
      if (data) {
        await this.$refs.driver.loadData();
      }
    },

    onOpenFormVehicle() {
      this.dialogVehicle = true;
    },

    onCloseFormVehicle() {
      this.$refs.vehicle.resetFields;
      this.dialogVehicle = false;
    },

    async onVehicleCreated(data) {
      if (data) {
        await this.$refs.vehicle.loadData();
      }
    },

    loadModalNewVehicle() {
      this.index++;
      let vehicle = this.vehicleCreate;
      this.vehicleCapture = vehicle;
      this.onOpenFormVehicle();
    },
  },
};
</script>

<style scoped>
@import './ModalFormCounterOfferStyle.scss';
</style>
